.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 33px !important;
  z-index: 1002;
  align-items: center;
  box-sizing: border-box;
  color: #fff;
  background-color: #232f3e;
  display: flex;
  padding: 0.4rem 1.5rem;
}
