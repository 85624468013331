.filterBoxWrapper {
  display: grid;
  grid-template-columns: 2fr 0.75fr 0.75fr 1.33fr;
  align-items: left;
  gap: 10px;
  padding-bottom: 0px;
}
.textFilter {
  width: 100%;
}
.selectWrapper {
  width: 100%;
}
.filterBox {
  flex-grow: 1;
}
