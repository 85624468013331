.awsui {
  padding-top: 55px !important;
  padding-bottom: 33px !important;
}

body:global(.awsui-polaris-compact-mode) :local(.awsui) {
  padding-top: 52px !important;
}

.dateCustomPicker {
  & div > div:nth-child(1) {
    width: 500px !important;
  }
}

.modalFooter {
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  padding-top: 16px;
  margin-top: 16px;
  margin-left: -16px;
  margin-right: -16px;
  padding-right: 16px;
  border-top: 1px solid #c2c2c2;
}

.modalContent {
  padding-top: 1rem;
}